.breadcrumbs {
  font-weight: 500;
  color: var(--c-highlight);
}
.breadcrumbs ul {
    margin: 0;
  }
.breadcrumbs ul li + li::before {
      content: ">";
      color: var(--c-text-subdued);
    }
