.card {
  background-color: var(--c-page-lift);
  border-radius: var(--s-4);
  max-width: 100%;
  box-shadow: 0 0 var(--s-4) var(--c-shadow);
  transition: var(--transition-default);
  cursor: pointer;
}

.card figure {
    aspect-ratio: 16/9;
    padding: var(--s0);
  }

.card figure img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }

.card .text {
    padding: var(--s0);
    padding-top: 0;
    text-align: center;
  }

.card .text a {
      color: inherit;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

.card:hover,
  .card:focus-within {
    scale: 1.04;
    box-shadow: 0 0 var(--s-2) var(--c-shadow-medium);
  }

.category-cards {
  display: grid;
  grid-gap: var(--s0);
  gap: var(--s0);
  grid-template-columns: repeat(auto-fit, minmax(var(--measure-half), 1fr));
}
