
  .content-article .prose {
    --margin: 1rem;

    padding-bottom: var(--s5);
    line-height: 1.5;
  }
.content-article .prose * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
.content-article .prose > * + * {
      margin-top: var(--margin);
    }
.content-article .prose > * + h2 {
      margin-top: calc(var(--margin) * 3);
    }
.content-article .prose > * + h3,
    .content-article .prose > * + h4 {
      margin-top: calc(var(--margin) * 2.25);
    }
.content-article .prose > * + h4 {
      margin-bottom: calc(var(--s-1) * -1);
    }
.content-article .prose > * + hr {
      margin: calc(var(--margin) * 3) 0;
    }
.content-article .prose blockquote {
      padding: var(--s1);
      outline: 1px solid transparent;
      outline-offset: -1px;
      background-color: var(--background-color);
      border-radius: var(--s-3);
      background-color: var(--c-page-lift);
      border: 1px solid var(--c-border);
      box-shadow: 0 0 var(--s-4) var(--c-shadow);
      color: var(--c-text-muted);
      font-size: var(--fs-small);
    }
.content-article .prose blockquote strong,
      .content-article .prose blockquote b {
        color: var(--c-text);
      }
.content-article .prose blockquote > * + * {
        margin-top: var(--margin);
      }
.content-article .prose h2 {
      padding-bottom: var(--margin);
      border-bottom: 1px solid var(--c-border);
    }
.content-article .prose [dir="rtl"] blockquote,
    .content-article .prose blockquote[dir="rtl"] {
      border-width: 0;
      border-right-width: $quote-border-width;
      margin-right: $quote-margin-start;
      padding-right: $quote-padding-start;
    }
.content-article .prose a {
      cursor: pointer;
    }
.content-article .prose li {
      margin-left: 1em;
    }
.content-article .prose .list-style\:none li {
      margin-left: 0;
    }
.content-article .prose ol {
      counter-reset: listCounter;
      list-style: none;
    }
.content-article .prose ol > li {
        counter-increment: listCounter;
        position: relative;
        padding-left: 1.5em;
        padding-right: 1.5em;
      }
.content-article .prose ol > li + li {
          margin-top: var(--s-3);
        }
.content-article .prose ol > li::before {
        content: counter(listCounter);
        display: inline-block;
        padding: var(--s-5);
        background-color: var(--c-highlight);
        border-radius: 999px;
        color: var(--c-white);
        font-family: var(--font-sans-serif);
        font-size: var(--fs-tiny);
        top: 0.25em;
        right: calc(100% - 1.5em);
        min-width: 1em;
        text-align: center;
        line-height: 1;
      }
.content-article .prose [dir="rtl"] li {
      margin-right: 1em;
    }
.content-article .prose code,
    .content-article .prose pre {
      display: inline-block;
      font-family: monospace;
      font-size: 0.8em;
      background-color: var(--c-text);
      color: var(--c-page);
      border-radius: 3px;
    }
.content-article .prose code {
      padding: 0 0.25em;
    }
.content-article .prose pre {
      width: 100%;
      vertical-align: top;
      padding: 0.5em;
      white-space: pre;
      overflow-x: auto;
      box-shadow: 0 0 var(--s-4) var(--c-shadow-light);
    }
.content-article .prose img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
.content-article .prose img.align-wide,
      .content-article .prose img.align-flush {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
.content-article .prose img.align-wide {
        width: var(--measure-medium);
        max-width: unquote("min(var(--measure-medium), 100vw)");
      }
.content-article .prose img.align-flush {
        width: 100vw;
        max-width: none;
        max-width: initial;
      }
.content-article .prose figcaption {
      font-size: var(--fs-tiny);
      color: var(--c-text-subdued);
    }
.content-article .prose .mention {
      display: inline-block;
      padding: 0 0.25em;
      border-radius: 3px;
      background-color: var(--c-green-translucent);
      color: var(--c-green);
    }
