/* Variables */

:root {
  --font-serif: "Addington", Georgia, serif;
  --font-sans-serif: "Greycliff", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  --font-monospace: ui-monospace, Menlo, Monaco, "Cascadia Mono",
    "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace",
    "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --fs-gigunta: var(--s4);
  --fs-biggest: var(--s3);
  --fs-bigger: var(--s2);
  --fs-big: var(--s1);
  --fs-default: var(--s0);
  --fs-small: calc(var(--fs-default) / 1.2);
  --fs-tiny: calc(var(--fs-small) / 1.2);
  --fs-micro: calc(var(--fs-tiny) / 1.2);

  --c-white: #fff;
  --c-white-translucent: rgba(255, 255, 255, 0.6);
  --c-paper: hsl(27, 24%, 91%);
  --c-paper-light-translucent: hsla(24, 33%, 97%, 0.16);
  --c-paper-light: hsl(36, 50%, 97%);
  --c-paper-pale: hsl(41, 40%, 99%);
  --c-paper-light-a0: hsla(41, 50%, 96%, 0);
  --c-paper-dark: hsla(34, 14%, 84%, 1);
  --c-warm-gray: #bec0c3;
  --c-gravel: #7d8186;
  --c-gravel-dark: #5d6167;
  --c-blue: #8296ab;
  --c-navy-light: hsl(212, 18%, 36%);
  --c-navy: hsl(212, 18%, 19%);
  --c-navy-translucent: hsla(212, 18%, 19%, 0.16);
  --c-navy-a0: hsla(212, 18%, 19%, 0);
  --c-navy-dark: hsl(212, 18%, 14%);
  --c-slate: #546578;
  --c-green: #4ba965;
  --c-green-translucent: rgba(75, 169, 101, 0.1);
  --c-green-dark: #27703b;
  --c-yellow: #ffbc65;
  --c-red-light: hsl(360, 92%, 44%);
  --c-red: hsl(360, 85%, 32%);
  --c-red-dark: hsl(360, 79%, 23%);
  --c-shadow-light: rgba(0, 0, 0, 0.07);
  --c-shadow: rgba(0, 0, 0, 0.1);
  --c-shadow-medium: rgba(0, 0, 0, 0.25);
  --c-shadow-dark: rgba(0, 0, 0, 0.45);

  --c-twitter: #4099ff;
  --c-facebook: #3b5998;
  --c-google: #d34836;

  --g-hero-background: radial-gradient(
      circle at 16% 83%,
      rgba(148, 148, 148, 0.1) 0%,
      rgba(148, 148, 148, 0.1) 50%,
      rgba(63, 63, 63, 0.1) 50%,
      rgba(63, 63, 63, 0.1) 100%
    ),
    radial-gradient(
      circle at 68% 87%,
      rgba(66, 66, 66, 0.1) 0%,
      rgba(66, 66, 66, 0.1) 50%,
      rgba(105, 105, 105, 0.1) 50%,
      rgba(105, 105, 105, 0.1) 100%
    ),
    radial-gradient(
      circle at 38% 50%,
      rgba(123, 123, 123, 0.1) 0%,
      rgba(123, 123, 123, 0.1) 50%,
      rgba(172, 172, 172, 0.1) 50%,
      rgba(172, 172, 172, 0.1) 100%
    ),
    linear-gradient(90deg, var(--c-hero-background), var(--c-hero-background));

  --ratio-large: 1.5;
  --ratio: 1.33;
  --ratio-small: 1.1;

  --s-7: calc(var(--s-6) / var(--ratio));
  --s-6: calc(var(--s-5) / var(--ratio));
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
  --s6: calc(var(--s5) * var(--ratio));
  --s7: calc(var(--s6) * var(--ratio));

  --measure: 60ch;
  --measure-medium: calc(var(--measure) * 1.33);
  --measure-large: calc(var(--measure) * 1.66);
  --measure-half: 30ch;
  --measure-small: 25ch;
  --measure-mobile-menu: min(100vw, 28rem);

  --line-height: var(--ratio-large);
  --line-height-small: var(--ratio-small);

  --border-hairline: 1px;
  --border-thin: 2px;
  --border-thick: var(--s-3);

  --transition-time: 200ms;
  --transition-time-quick: 120ms;
  --transition-curve: cubic-bezier(0.65, 0, 0.35, 1);
  --transition-default: all var(--transition-time) var(--transition-curve);
  --transition-quick: all var(--transition-time-quick) var(--transition-curve);

  --c-highlight: var(--c-green);
  --c-subdued: var(--c-gravel);
  --c-subdued-dark: var(--c-gravel-dark);
  --c-page: var(--c-paper-light);
  --c-page-translucent: var(--c-paper-light-translucent);
  --c-page-subdued: var(--c-paper-dark);
  --c-page-lift: var(--c-paper-pale);
  --c-page-fade: var(--c-paper-light-a0);
  --c-offset-light: var(--c-white);
  --c-offset-dark: var(--c-paper);
  --c-error: var(--c-red);

  --c-text: var(--c-navy);
  --c-text-muted: var(--c-navy-light);
  --c-text-subdued: var(--c-gravel);
  --c-text-inverse: var(--c-paper-light);
  --c-text-inverse-subdued: var(--c-paper);
  --c-text-inverse-max: var(--c-white);
  --c-text-danger: var(--c-red);

  --c-border: var(--c-paper-dark);
  --c-border-heavy: var(--c-warm-gray);
  --c-border-inverse: var(--c-navy);

  --c-dropdown-text: var(--c-white);
  --c-dropdown-heading: var(--c-white-translucent);
  --c-dropdown-background: var(--c-navy);
  --c-dropdown-border: var(--c-navy-light);
  --c-dropdown-item-highlight: var(--c-slate);

  --c-hero-background: var(--c-navy);

  --c-notification-text: var(--c-paper-light);
  --c-notification-background: var(--c-navy);
  --c-notification-countdown: var(--c-blue);

  line-height: 1.33;

  line-height: var(--ratio);
  font-family: "Addington", Georgia, serif;
  font-family: var(--font-serif);
}

@media (prefers-color-scheme: dark) {
  :root {
    --c-highlight: var(--c-green);
    --c-subdued: var(--c-gravel);
    --c-subdued-dark: var(--c-warm-gray);
    --c-page: var(--c-navy);
    --c-page-translucent: var(--c-navy-translucent);
    --c-page-subdued: var(--c-navy-light);
    --c-page-lift: var(--c-navy-dark);
    --c-page-fade: var(--c-navy-a0);
    --c-offset-light: var(--c-navy-dark);
    --c-offset-dark: var(--c-navy-light);

    --c-text: var(--c-paper);
    --c-text-muted: var(--c-paper);
    --c-text-subdued: var(--c-warm-gray);
    --c-text-inverse: var(--c-navy);
    --c-text-inverse-subdued: var(--c-navy-light);
    --c-text-inverse-max: var(--c-navy-dark);

    --c-border: hsla(27, 24%, 91%, 0.1);
    --c-border-inverse: var(--c-border);

    --c-dropdown-text: var(--c-navy-dark);
    --c-dropdown-heading: var(--c-gravel);
    --c-dropdown-background: var(--c-paper-light);
    --c-dropdown-border: var(--c-paper-dark);
    --c-dropdown-item-highlight: var(--c-paper-dark);

    --c-notification-text: var(--c-navy-dark);
    --c-notification-background: var(--c-paper-light);
    --c-notification-countdown: var(--c-paper-dark);

    --c-hero-background: var(--c-paper-light);

    --c-shadow: hsla(30, 29%, 97%, 0.1);
    --c-shadow-medium: hsla(30, 29%, 97%, 0.25);
    --c-shadow-dark: hsla(30, 29%, 97%, 0.45);
  }
}
