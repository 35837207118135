/* Base Typography */

html {
  font-size: 1.125em;
  font-size: calc(1.125em + 0.25vw);
}

body {
  background-color: var(--c-page);
  color: var(--c-text);
}

body * {
  font-feature-settings: common-ligatures discretionary-ligatures contextual, "lnum";
  font-variant-ligatures: common-ligatures discretionary-ligatures contextual;
  font-variant-alternates: styleset(alternates);
  font-variant-numeric: lining-nums;
}

/* Basic Typography */
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
  font-family: var(--font-sans-serif);
  line-height: var(--line-height-small);
  font-weight: 900;
  text-transform: none;
  text-transform: initial;
  letter-spacing: normal;
  letter-spacing: initial;
}

h1,
.h1 {
  font-size: var(--fs-bigger);

  @include breakpoint-min(medium) {
    font-size: var(--fs-biggest);
  }
}

h1 + .eyebrow, .h1 + .eyebrow {
    order: -1;
    margin: 0 0 var(--s-4);
  }

h2,
.h2 {
  font-size: var(--fs-big);
  font-weight: 700;
  line-height: 1.33;

  @include breakpoint-min(medium) {
    font-size: var(--fs-bigger);
  }
}

h3,
h4,
.h3,
.h4 {
  line-height: 1.5;
}

h3,
.h3 {
  color: var(--c-text-muted);
  font-size: var(--fs-tiny);
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

h4,
.h4 {
  font-weight: 700;
  font-size: var(--fs-small);
}

a {
  color: var(--c-green);
}

a.color\:inherit {
    color: inherit;
  }

a.text-decoration\:none {
    -webkit-text-decoration: none;
    text-decoration: none;
  }

a.text-decoration\:none:focus,
    a.text-decoration\:none:hover,
    a.text-decoration\:none:active {
      -webkit-text-decoration: underline;
      text-decoration: underline;
    }

.highlight-links a:not(.btn) {
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    outline: none;
  }

.highlight-links a:not(.btn) > span:not(.avatar) {
      line-height: 1.3;
      background-image: linear-gradient(
        to right,
        var(--c-highlight) 0%,
        var(--c-highlight) 100%
      );
      background-repeat: no-repeat;
      background-size: 0 var(--s-7);
      background-position: left bottom;
      transition: var(--transition-quick);
      transition-property: background-size;
    }

.highlight-links a:not(.btn):hover > span:not(.avatar), .highlight-links a:not(.btn):focus > span:not(.avatar), .highlight-links a:not(.btn):active > span:not(.avatar), .highlight-links a.active:not(.btn) > span:not(.avatar), .highlight-links a[aria-current="page"]:not(.btn) > span:not(.avatar) {
        background-size: 100% var(--s-6);
        outline: 0;
      }

ul {
  padding-left: var(--s1);
  padding-right: var(--s1);
}

ol {
  counter-reset: listCounter;
  list-style: none;
}

ol > li {
    counter-increment: listCounter;
    position: relative;
    padding-left: var(--s2);
    padding-right: var(--s2);
  }

ol > li::before {
    content: counter(listCounter) ".";
    font-weight: bold;
    position: absolute;
    right: calc(100% - 0.5em);
    text-align: right;
  }

ol.highlighted > li::before {
      content: counter(listCounter);
      display: inline-block;
      padding: var(--s-7) var(--s-3);
      background-color: var(--c-highlight);
      border-radius: 999px;
      color: var(--c-paper-light-translucent);
      font-family: var(--font-sans-serif);
      font-size: var(--fs-tiny);
      top: 0.125em;
      right: calc(100% - 1.5em);
    }

figcaption,
.note {
  color: var(--c-text-subdued);
  font-size: var(--fs-small);
}

figcaption {
  text-align: center;
}

/* Monospace fonts */

code {
  font-family: var(--font-monospace);
  font-size: 0.85em;
}

/* Specific text treatments */

.section-title,
.section-title-big {
  font-family: var(--font-sans-serif);
  font-size: var(--fs-small);
  font-weight: 900;
  text-transform: none;
  text-transform: initial;
  letter-spacing: normal;
  letter-spacing: initial;
  color: var(--c-text-muted);
}

.section-title-big {
  color: var(--c-text);
  font-size: var(--fs-default);
}

.text--menu-item {
  font-size: var(--fs-small);
  font-weight: 500;
}

.text--meta {
  font-weight: 500;
}

.truncatable {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  max-width: 100%;
}

/* Utility Classes */

.font-family\:serif {
  font-family: var(--font-serif);
}

.font-family\:sans-serif {
  font-family: var(--font-sans-serif);
}

.font-family\:monospace {
  font-family: var(--font-monospace);
}

.color\:default {
  color: var(--c-text);
}

.color\:muted {
  color: var(--c-text-muted);
}

.color\:subdued {
  color: var(--c-text-subdued);
}

.color\:highlight {
  color: var(--c-highlight);
}

.color\:danger {
  color: var(--c-text-danger);
}

.color\:inherit {
  color: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.list-style\:none {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
}

.font-size\:micro {
  font-size: var(--fs-micro);
}

.font-size\:tiny {
  font-size: var(--fs-tiny);
}

.font-size\:small {
  font-size: var(--fs-small);
}

.font-size\:default {
  font-size: var(--fs-default);
}

.font-size\:big {
  font-size: var(--fs-big);
}

.font-size\:bigger {
  font-size: var(--fs-bigger);
}

.font-size\:biggest {
  font-size: var(--fs-biggest);
}

.font-size\:gigunta {
  font-size: var(--fs-gigunta);
}

.font-weight\:black {
  font-weight: 900;
}

.font-weight\:bold {
  font-weight: 700;
}

.font-weight\:medium {
  font-weight: 500;
}

.font-weight\:normal {
  font-weight: 400;
}

.font-weight\:light {
  font-weight: 300;
}

.font-style\:italic {
  font-style: italic;
}

.font-style\:normal {
  font-style: normal;
}

.text-align\:left {
  text-align: left;
}

.text-align\:center {
  text-align: center;
}

.text-align\:right {
  text-align: right;
}

.text-transform\:none {
  text-transform: none;
}

.text-transform\:uppercase {
  text-transform: uppercase;
}

.text-transform\:lowercase {
  text-transform: lowercase;
}

.text-transform\:capitalize {
  text-transform: capitalize;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

hr {
  height: 0;
  border: 0;
  border-bottom: 1px solid var(--c-border);
}
