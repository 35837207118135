html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-height: 100dvh;
}

body {
  display: flex;
  flex-direction: column;
}

body .non-footer {
    flex: 1 0 auto;
  }

body .non-footer + footer {
      flex-shrink: 0;
    }
