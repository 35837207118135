/* Font Face definitions */

@font-face {
  font-family: "Addington";
  src: url("https://assets.groupnews.com/font/addington/AddingtonCF-Light.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/addington/AddingtonCF-Light.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Addington";
  src: url("https://assets.groupnews.com/font/addington/AddingtonCF-LightItalic.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/addington/AddingtonCF-LightItalic.woff")
      format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Addington";
  src: url("https://assets.groupnews.com/font/addington/AddingtonCF-Regular.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/addington/AddingtonCF-Regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Addington";
  src: url("https://assets.groupnews.com/font/addington/AddingtonCF-RegularItalic.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/addington/AddingtonCF-RegularItalic.woff")
      format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Addington";
  src: url("https://assets.groupnews.com/font/addington/AddingtonCF-Medium.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/addington/AddingtonCF-Medium.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Addington";
  src: url("https://assets.groupnews.com/font/addington/AddingtonCF-Bold.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/addington/AddingtonCF-Bold.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Addington";
  src: url("https://assets.groupnews.com/font/addington/AddingtonCF-BoldItalic.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/addington/AddingtonCF-BoldItalic.woff")
      format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Addington";
  src: url("https://assets.groupnews.com/font/addington/AddingtonCF-ExtraBold.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/addington/AddingtonCF-ExtraBold.woff")
      format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("https://assets.groupnews.com/font/greycliff/GreycliffCF-Light.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/greycliff/GreycliffCF-Light.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("https://assets.groupnews.com/font/greycliff/GreycliffCF-LightItalic.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/greycliff/GreycliffCF-LightItalic.woff")
      format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("https://assets.groupnews.com/font/greycliff/GreycliffCF-Regular.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/greycliff/GreycliffCF-Regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("https://assets.groupnews.com/font/greycliff/GreycliffCF-RegularItalic.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/greycliff/GreycliffCF-RegularItalic.woff")
      format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("https://assets.groupnews.com/font/greycliff/GreycliffCF-DemiBold.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/greycliff/GreycliffCF-DemiBold.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("https://assets.groupnews.com/font/greycliff/GreycliffCF-Bold.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/greycliff/GreycliffCF-Bold.woff")
      format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("https://assets.groupnews.com/font/greycliff/GreycliffCF-BoldItalic.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/greycliff/GreycliffCF-BoldItalic.woff")
      format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Greycliff";
  src: url("https://assets.groupnews.com/font/greycliff/GreycliffCF-ExtraBold.woff2")
      format("woff2"),
    url("https://assets.groupnews.com/font/greycliff/GreycliffCF-ExtraBold.woff")
      format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-feature-values "Addington" {
  @styleset {
    alternates: 1;
  }
}
