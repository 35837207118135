/* Buttons */

.btn {
  --background-color: var(--c-highlight);
  --border-color: var(--c-highlight);
  --text-color: var(--c-page);
  --shadow-color: var(--c-shadow-medium);
  --border-width: var(--border-thin);
  --padding: var(--s-1) var(--s0);

  border: var(--border-thin) solid var(--c-highlight);

  border: var(--border-width) solid var(--border-color);
  background-color: var(--c-highlight);
  background-color: var(--background-color);
  color: var(--c-page);
  color: var(--text-color);
  fill: var(--c-page);
  fill: var(--text-color);
  border-radius: var(--s-6);
  display: inline-block;
  line-height: 1;
  padding: var(--s-1) var(--s0);
  padding: var(--padding);
  transition: all var(--transition-time-quick) var(--transition-curve);
  font-family: var(--font-sans-serif);
  font-size: var(--fs-small);
  font-size: max(var(--fs-tiny), min(3vw, var(--fs-small)));
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
          user-select: none;
  outline-offset: var(--s-4);
  box-shadow: 0 1px var(--s-7) var(--c-shadow-medium);
  box-shadow: 0 1px var(--s-7) var(--shadow-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  box-sizing: border-box;
}

.btn svg {
    display: block;
    transition: all var(--transition-time) var(--transition-curve);
  }

.btn svg:not(.icon) {
    fill: inherit;
  }

.btn:focus:not(:focus-visible) {
    outline: none;
  }

.btn:not(:disabled):hover,
    .btn:not(:disabled):focus,
    .btn:not(:disabled):focus-within {
      filter: brightness(115%);
      border-color: var(--focus-border-color, var(--background-color));
      background-color: var(--focus-background-color, var(--background-color));
      color: var(--focus-text-color, var(--text-color));
      fill: var(--focus-text-color, var(--text-color));
      outline: none;
    }

.btn:not(:disabled):active {
      background-color: var(--active-background-color, var(--c-green-dark));
      border-color: var(--active-border-color, var(--c-green-dark));
      color: var(--active-text-color, var(--c-page));
    }

.btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
.btn-outline {
  --background-color: transparent;
  --text-color: var(--c-highlight);
  --focus-border-color: var(--c-highlight);
  --focus-background-color: var(--c-highlight);
  --focus-text-color: var(--c-page);
}

.btn-ghost {
  --background-color: transparent;
  --border-color: transparent;
  --text-color: inherit;
  --focus-background-color: transparent;
  --focus-border-color: transparent;
  --focus-text-color: inherit;
  --active-background-color: transparent;
  --active-border-color: transparent;
  --active-text-color: inherit;

  box-shadow: none;
}

.btn-ghost:not(:disabled):focus-within,
    .btn-ghost:not(:disabled):hover,
    .btn-ghost:not(:disabled):active {
      filter: initial;
      background-color: var(--c-offset-light);
      outline: 0;
      box-shadow: 0 0 var(--s-1) var(--c-shadow);
      -webkit-text-decoration: none;
      text-decoration: none;
    }

.btn-secondary {
  --background-color: var(--c-subdued);
  --border-color: var(--c-subdued);
  --focus-background-color: var(--c-subdued);
  --focus-border-color: var(--c-subdued);
  --active-background-color: var(--c-subdued-dark);
  --active-border-color: var(--c-subdued-dark);
}

.btn-secondary.btn-outline {
    --background-color: transparent;
    --text-color: var(--c-subdued);
    --focus-text-color: var(--c-page);
  }

.btn-secondary.btn-outline.btn-light {
      --background-color: transparent;
    }

.btn-secondary.btn-ghost {
    --background-color: transparent;
    --focus-text-color: var(--c-page);
    --border-color: transparent;
  }

.btn-secondary-dark {
  --background-color: var(--c-text);
  --border-color: var(--c-text);
  --focus-background-color: var(--c-text);
  --focus-border-color: var(--c-text);
  --active-background-color: var(--c-text);
  --active-border-color: var(--c-text);
}

.btn-secondary-dark.btn-outline {
    --background-color: var(--c-page);
    --text-color: var(--c-subdued);
    --border-color: var(--c-subdued);
    --focus-background-color: var(--c-page-lift);
    --focus-text-color: var(--c-text);
    --active-background-color: var(--c-page-lift);
    --active-text-color: var(--c-text);
  }

.btn-danger {
  --background-color: var(--c-red);
  --border-color: var(--c-red);
  --text-color: var(--c-white);
  --focus-background-color: var(--c-red);
  --focus-border-color: var(--c-red);
  --active-background-color: var(--c-red-dark);
  --active-border-color: var(--c-red-dark);
}

.btn-danger.btn-outline {
    --background-color: transparent;
    --text-color: var(--c-red);
    --focus-text-color: var(--c-page);
  }

.btn-tiny {
  --padding: var(--s-3) var(--s-3);
  font-size: var(--fs-micro);
}

.btn-tiny .icon {
    --min-icon-size: 1.33em;
  }

.btn-small {
  --padding: var(--s-2) var(--s-2);
  font-size: var(--fs-tiny);
}

.btn-large {
  --padding: var(--s0) var(--s1);
  font-size: var(--s0);
  font-size: max(var(--s-1), min(4vw, var(--s0)));
}
