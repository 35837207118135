
  .site-header .logo {
    display: block;
  }
.site-header .logo svg {
    display: block;
    width: 100%;
    max-width: 20ch;
    color: var(--c-text);
  }
.site-header nav ul {
      margin: 0;
      padding: 0;
    }
