.tabs {
  --c-tab-inactive: transparent;
  --c-tab-inactive-text: var(--c-text-inverse);
  --c-tab-hover: var(--c-page-translucent);
  --c-tab-hover-text: var(--c-text-inverse);
  --c-tab-active: var(--c-page);
  --c-tab-active-text: var(--c-text);

  font-family: var(--font-sans-serif);
  margin: 0;
  padding: 0;
}
.tabs .tab {
    display: inline-block;
    padding: var(--s-2) var(--s-1);
    font-weight: 500;
    font-size: var(--fs-small);
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: var(--c-tab-inactive);
    color: var(--c-tab-inactive-text);
    border-top-left-radius: var(--s-7);
    border-top-right-radius: var(--s-7);
    transition: background-color var(--transition-time) var(--transition-curve);
  }
.tabs .tab:not([aria-current="page"]):hover,
      .tabs .tab:not([aria-current="page"]):focus {
        background-color: var(--c-tab-hover);
        color: var(--c-tab-hover-text);
      }
.tabs .tab[aria-current="page"] {
      background-color: var(--c-tab-active);
      color: var(--c-tab-active-text);
    }
