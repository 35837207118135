/* Site Footer */

.site-footer {
  background-color: var(--c-offset-dark);
  border-top: 1px solid var(--c-border);
  padding: var(--s0) var(--s-1) var(--s-2);
  padding-bottom: max(var(--s-2), env(safe-area-inset-bottom));
  position: relative;
  z-index: 2;
}

.site-footer .logo {
    color: var(--c-text-muted);
  }

.site-footer a {
    font-weight: bold;
    color: var(--c-text-muted);
    -webkit-text-decoration: none;
    text-decoration: none;
  }

.site-footer section a {
      display: block;
      padding: var(--s-6) 0;
    }

.site-footer .wrapper {
    display: grid;
    grid-gap: var(--s1);
    gap: var(--s1);
  }

.site-footer .wrapper > * {
      font-size: var(--fs-tiny);
    }

.site-footer .about .logo {
      display: block;
      width: min(40vw, 160px);
    }

.site-footer .about p {
      max-width: 45ch;
    }

.site-footer .copyright {
    width: 100%;
    border-top: 1px solid var(--c-border);
    padding-top: var(--s-2);
    display: flex;
    flex-direction: column;
    gap: var(--s-4);
    color: var(--c-text-muted);
    font-size: var(--fs-micro);
    grid-column: 1/-1;
  }

.site-footer .copyright a {
      color: inherit;
    }

.site-footer .copyright ul {
      padding: 0;
    }

.site-footer .copyright ul > li + li::before {
        content: " • ";
        display: inline-block;
        margin-right: var(--s-4);
      }

@media (prefers-color-scheme: dark) {

.site-footer {
    background-color: var(--c-offset-light);
}

    .site-footer .logo {
      color: var(--c-blue);
    }
  }

@media (min-width: 375px) {
    .site-footer .wrapper {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto auto auto;
    }

    .site-footer .about {
      grid-column: 1 / 3;
    }

    .site-footer .product,
    .site-footer .help,
    .site-footer .company {
      grid-row: 2 / 3;
    }

    .site-footer .copyright {
      grid-row: 3/4;
    }
  }

@media (min-width: 768px) {
    .site-footer .wrapper {
      grid-template-columns: 2fr 1fr 1fr 1fr;
      grid-template-rows: auto auto;
    }

    .site-footer .about {
      grid-column: 1 / 2;
    }

    .site-footer .about,
    .site-footer .product,
    .site-footer .help,
    .site-footer .company {
      grid-row: 1 / 2;
    }

    .site-footer .copyright {
      grid-row: 2/3;
      flex-direction: row;
      justify-content: space-between;
    }
  }

@media (min-width: 1024px) {
    .site-footer .wrapper {
      grid-template-columns: 3fr 1fr 1fr 1fr;
    }
  }
