[class*="hero"] {
  background-color: var(--c-hero-background);
  background-image: var(--g-hero-background);
  color: var(--c-text-inverse-subdued);
  border-radius: 0;
}

[class*="hero"] .title {
    color: var(--c-text-inverse-max);
  }

/* To account for stupid rounding errors in Safari */

[class*="hero"] .tabs {
    margin-bottom: -2px;
  }

[class*="hero"] .tabs .tab {
      padding-bottom: calc(var(--s-2) + 2px);
    }

.category-hero {
  padding-bottom: 0;
}
