.article-group + .article-group {
  border-top: 1px solid var(--c-border);
  padding-top: var(--space);
}

.article-group .article-list {
    list-style: none;
    padding: 0;
    font-size: var(--fs-small);
  }

.article-group .article-list li {
      margin: 0 0 var(--s-2);
      padding: 0 0 0 var(--s0);
      position: relative;
    }

.article-group .article-list li::before {
        content: "•";
        color: var(--c-highlight);
        font-weight: bold;
        position: absolute;
        left: 0;
      }

@media screen and (min-width: 768px) {

.article-group .article-list {
      align-items: start;
      column-count: 2;
      column-gap: var(--s0);
  }

      .article-group .article-list li {
        display: block;
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }
