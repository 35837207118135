.box,
[class*="box-"] {
  --padding-x: var(--s0);
  --padding-y: var(--s0);
  --border-color: var(--c-border);
  --background-color: transparent;
  --border-radius: var(--s-4);

  padding: var(--s0) var(--s0);

  padding: var(--padding-y) var(--padding-x);
  border: 0 solid;
  outline: var(--border-thin) solid transparent;
  outline-offset: calc(var(--border-thin) * -1);
  background-color: transparent;
  background-color: var(--background-color);
  border-radius: var(--s-4);
  border-radius: var(--border-radius);
}

.box.with-border-bottom, .with-border-bottom[class*="box-"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: var(--border-hairline) solid var(--border-color);
  }

.box.with-border-top, .with-border-top[class*="box-"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: var(--border-hairline) solid var(--border-color);
  }

.box.invert, .invert[class*="box-"] {
    color: var(--color-light);
    background-color: var(--color-dark);
    stroke: none;
    fill: var(--color-light);
  }

.box.full-width, .full-width[class*="box-"] {
    border-left-width: 0 !important;
    border-right-width: 0 !important;
  }

.box.full-width, .full-width[class*="box-"] {
    position: relative;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    border-radius: 0;
  }

.box > .box,
  [class*="box-"] > .box,
  .box [class*="box-"],
  [class*="box-"] [class*="box-"] {
    --padding-x: 0;
  }

.box-tight {
  --padding-x: var(--s-2);
  --padding-y: var(--s-2);
}

.box-flush {
  --padding-x: 0;
  --padding-y: 0;
}

.box-flush > .box:not(.box-highlight):not(.invert) {
    --padding-x: var(--s-1);
  }

.box-px-0 {
  --padding-x: 0;
}

.box-py-0 {
  --padding-y: 0;
}

.box-comfy {
  --padding-y: var(--s3);
}

.box-roomy {
  --padding-y: var(--s5);
}

.box-highlight {
  --border-color: var(--c-border);
  --background-color: var(--c-page-lift);
  --border-width: var(--border-thick);

  border-radius: var(--s-3);
  background-color: var(--c-page-lift);
  background-color: var(--background-color);
  border: var(--border-thick) solid var(--c-border);
  border: var(--border-width) solid var(--border-color);
  box-shadow: 0 0 var(--s-4) var(--c-shadow);
}

.box-highlight.inset {
    box-shadow: 0 0 var(--s-4) var(--c-shadow) inset;
  }

.box-highlight.border-thin {
    --border-width: var(--border-thin);
  }

.box-highlight.border-hairline {
    --border-width: var(--border-hairline);
  }

.box-highlight.borderless {
    --border-width: 0;
  }

.box-highlight .box-highlight {
    --background-color: var(--c-offset-light);
  }
